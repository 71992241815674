import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function createFarm(data) {
  return axios.post(baseUrl + "/farm", data, { headers });
}
export function fetchFarmList() {
  return axios.get(baseUrl + `/farm/list`, { headers });
}
export function fetchUserFarmList(params) {
  return axios.get(baseUrl + `/farm/user/list`, { params, headers });
}
export function fetchFarmDetails(id) {
  return axios.get(baseUrl + `/farm/${id}`, { headers });
}
export function deleteFarm(id) {
  return axios.delete(baseUrl + `/farm/${id}`, {
    headers,
  });
}
export function updateFarm(data) {
  return axios.put(baseUrl + `/farm`, data, {
    headers,
  });
}
export function fetchWeather(params) {
  return axios.get(baseUrl + `/farm/weather`, { params, headers });
}
export function fetchFarmerList(params) {
  return axios.get(baseUrl + `/farm/farmer/list`, { params, headers });
}
