import Vuex from "vuex";
import Vue from "vue";
import state from "./state";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  mutations,
  actions,
});

const token = localStorage.getItem("accesstoken");
const userType = localStorage.getItem("userType");
if (token) {
  let data = {
    accesstoken: token,
    userType: userType,
  };
  store.commit("LOGIN", data);
}
export default store;
