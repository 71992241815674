import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function login(data) {
  return axios.post(baseUrl + "/accounts/login", data, { headers });
}
export function fetchProfile() {
  return axios.get(baseUrl + `/accounts/profile`, { headers });
}
export function createUser(data) {
  return axios.post(baseUrl + "/accounts/join", data, { headers });
}

export function createCertNumber(data) {
  return axios.post(baseUrl + `/auth/sms`, data, { headers });
}
export function verifyMobile(data) {
  return axios.post(baseUrl + `/auth/sms/verify`, data, { headers });
}
export function updatePwd(data) {
  return axios.put(baseUrl + `/accounts/pwd`, data, {
    headers,
  });
}
export function updateUserPwd(data) {
  return axios.put(baseUrl + `/accounts/user/pwd`, data, {
    headers,
  });
}
export function updateUser(data) {
  return axios.put(baseUrl + `/accounts/profile`, data, {
    headers,
  });
}
export function updateAppAlarm(data) {
  return axios.put(baseUrl + `/accounts/appAlarm`, data, {
    headers,
  });
}
export function updateUserApptoken(data) {
  return axios.put(baseUrl + `/accounts/apptoken`, data, {
    headers,
  });
}
export function updateUserApptokenWithUserId(data) {
  return axios.put(baseUrl + `/accounts/apptokenuser`, data, {
    headers,
  });
}
export function fetchFarmerList(params) {
  return axios.get(baseUrl + `/accounts/farmer/list`, { params, headers });
}
export function fetchNoticeList(params) {
  return axios.get(baseUrl + `/notice/list`, { params, headers });
}
export function fetchNoticeDetail(id) {
  return axios.get(baseUrl + `/notice/${id}`, { headers });
}
//회원탈퇴
export function createWithdraw() {
  return axios.get(baseUrl + "/accounts/withdraw", { headers });
}
