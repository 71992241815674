<template>
  <nav>
    <div>
      <button @click="goBack()"><span class="material-icons-round"> arrow_back_ios </span></button>
      <h4>{{ pageName }}</h4>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    pageName: {
      type: String,
    },
  },
  methods: {
    goBack() {
      this.$store.commit("setPageTransition", "back");
      this.$router.go(-1);
    },
  },
};
</script>
