import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const requireAuth = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  if (accesstoken == null || accesstoken == undefined) {
    next("/login");
  } else {
    next();
  }
  next();
};

const redirect = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  let userType = localStorage.getItem("userType");
  localStorage.setItem("appToken", to.query.token);
  if (accesstoken) {
    if (userType == "EXAMINER") {
      next("/examinerHome");
    } else {
      next("/home");
    }
  } else {
    next();
  }
};

const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/",
    name: "login",
    component: () => import("../views/account/Login.vue"),
    beforeEnter: redirect(),
  },
  {
    path: "/examinerHome",
    name: "examinerHome",
    component: () => import("../views/HomeByExaminer.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/Login.vue"),
    beforeEnter: redirect(),
  },
  {
    path: "/join",
    name: "join",
    component: () => import("../views/account/Join.vue"),
  },
  {
    path: "/joinDone",
    name: "joinDone",
    component: () => import("../views/account/JoinDone.vue"),
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: () => import("../views/account/ChangePassword.vue"),
  },
  {
    path: "/changePasswordDone",
    name: "changePasswordDone",
    component: () => import("../views/account/ChangePasswordDone.vue"),
  },

  {
    path: "/error503",
    name: "error503",
    component: () => import("../views/pop/Error503.vue"),
  },
  {
    path: "/mypage",
    name: "mypage",
    component: () => import("../views/mypage/Mypage.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/mypage/userInfo",
    name: "userInfo",
    component: () => import("../views/mypage/UserInfo.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/mypage/changePw",
    name: "changePw",
    component: () => import("../views/mypage/UserPwd.vue"),
  },
  {
    path: "/growthRegister",
    name: "growthRegister",
    component: () => import("../views/growth/GrowthRegister.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/growthList",
    name: "growthList",
    component: () => import("../views/growth/GrowthList.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/growthDetail",
    name: "growthDetail",
    component: () => import("../views/growth/GrowthDetail.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/daily",
    name: "daily",
    component: () => import("../views/daily/DailyHome.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/dailyRegister",
    name: "dailyRegister",
    component: () => import("../views/daily/DailyRegister.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/dailyDetail",
    name: "dailyDetail",
    component: () => import("../views/daily/DailyDetail.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/cultureInfo",
    name: "cultureInfo",
    component: () => import("../views/cultureInfo/CultureInfoHome.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/cultureInfoList",
    name: "cultureInfoList",
    component: () => import("../views/cultureInfo/cultureInfoList.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/cultureInfoList/Orange",
    name: "cultureInfoListOrange",
    component: () => import("../views/cultureInfo/cultureInfoList_Orange.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/cultureInfoDetail",
    name: "cultureInfoDetail",
    component: () => import("../views/cultureInfo/CultureInfoDetail.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/mypage/farmList",
    name: "farmList",
    component: () => import("../views/mypage/FarmList.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/mypage/farmRegister",
    name: "farmRegister",
    component: () => import("../views/mypage/FarmRegister.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/mypage/farmDetail",
    name: "farmDetail",
    component: () => import("../views/mypage/FarmDetail.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/weather",
    name: "weather",
    component: () => import("../views/Weather.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/Faq.vue"),
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import("../views/notice/Notice.vue"),
  },
  {
    path: "/noticeDetail",
    name: "noticeDetail",
    component: () => import("../views/notice/NoticeDetail.vue"),
  },
  {
    path: "/harvest",
    name: "harvest",
    component: () => import("../views/Harvest.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/terms/UserTerms.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/terms/TermsPrivacy.vue"),
  },
  {
    path: "/greenlens/privacy",
    name: "greenlensprivacy",
    component: () => import("../views/terms/TermsPrivacyGreenLens.vue"),
  },
  {
    path: "/marketingAgreement",
    name: "marketingAgreement",
    component: () => import("../views/terms/MarketingAgreement.vue"),
  },
  {
    path: "*",
    name: "error404",
    component: () => import("../views/pop/Error404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
