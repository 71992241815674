import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function fetchGrowthList(params) {
  return axios.get(baseUrl + `/growth/list`, { params, headers });
}
export function fetchUserGrowthList(params) {
  return axios.get(baseUrl + `/growth/user/list`, { params, headers });
}
export function fetchGrowthDetail(id) {
  return axios.get(baseUrl + `/growth/${id}`, { headers });
}
export function createGrowth(data) {
  return axios.post(baseUrl + `/growth`, data, { headers });
}
export function updateGrowth(data) {
  return axios.put(baseUrl + `/growth`, data, { headers });
}
export function deleteGrowth(id) {
  return axios.delete(baseUrl + `/growth/${id}`, { headers });
}
export function fetchModel(id) {
  return axios.get(baseUrl + `/growth/model/${id}`, { headers });
}
export function createModel(data) {
  return axios.post(baseUrl + `/growth/model`, data, { headers });
}
