<template>
  <div class="menu">
    <ul>
      <router-link tag="li" to="/examinerHome" :class="{ active: navbarState == 0 }" v-if="userType == 'EXAMINER'">
        <img src="@/assets/images/navIcon0.png" alt="홈" />
        <img class="active" src="@/assets/images/navIcon0_active.png" alt="홈" />
        <p>홈</p>
      </router-link>
      <router-link tag="li" to="/home" :class="{ active: navbarState == 1 }" v-if="userType == 'EXAMINER' && mainFarm">
        <img src="@/assets/images/navIcon1.png" alt="홈" />
        <img class="active" src="@/assets/images/navIcon1_active.png" alt="홈" />
        <p>농장관리</p>
      </router-link>
      <router-link tag="li" to="/home" :class="{ active: navbarState == 1 }" v-if="userType == 'USER'">
        <img src="@/assets/images/navIcon1.png" alt="내농장" />
        <img class="active" src="@/assets/images/navIcon1_active.png" alt="내농장" />
        <p>내농장</p>
      </router-link>
      <router-link tag="li" to="/cultureInfo" :class="{ active: navbarState == 2 }">
        <img src="@/assets/images/navIcon2.png" alt="병해충" />
        <img class="active" src="@/assets/images/navIcon2_active.png" alt="병해충" />
        <p>재배정보</p>
      </router-link>
      <router-link tag="li" to="/harvest" :class="{ active: navbarState == 3 }">
        <img src="@/assets/images/navIcon3.png" alt="수확량예측" />
        <img class="active" src="@/assets/images/navIcon3_active.png" alt="수확량예측" />
        <p>수확량예측</p>
      </router-link>
      <router-link tag="li" to="/mypage" :class="{ active: navbarState == 4 }">
        <img src="@/assets/images/navIcon4.png" alt="마이페이지" />
        <img class="active" src="@/assets/images/navIcon4_active.png" alt="마이페이지" />
        <p>마이페이지</p>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["navbarState", "userType", "mainFarm"]),
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("LOGOUT");
    },
    handleMove(path) {
      this.$router.push(path);
      this.handleClose();
    },
    handleOpen() {
      document.querySelector(".inner").classList.add("active");
      document.querySelector(".inner").classList.remove("hide");
    },
    handleClose() {
      document.querySelector(".inner").classList.add("hide");
      document.querySelector(".inner").classList.remove("active");
    },
  },
};
</script>
