import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function fetchDeviceValues() {
  return axios.get(baseUrl + `/device/values`, { headers });
}

export function fetchDeviceValue() {
  return axios.get(baseUrl + `/device/value`, { headers });
}

export function fetchDeviceInfoForrn400(data) {
  return axios.post(baseUrl + `/device/info/rn400`, data, { headers });
}

export function fetchDeviceInfoForrn171(data) {
  return axios.post(baseUrl + `/device/info/rn171`, data, { headers });
}

export function fetchDeviceTrendKey(params) {
  return axios.get(baseUrl + `/device/trend/key`, { params, headers });
}
