const state = {
  id: null,
  userId: null,
  status: "",
  mobileNo: "",
  username: "",
  userType: "",
  appAlarm: null,
  isLogin: false,
  navVisible: false,
  menuVisible: false,
  footerVisible: false,
  loading: false,
  navbarState: 1,
  pageName: "",
  mainFarm: "",
  weather: "",
  devices: "",
  weatherErr: false,
  farmList: [],
  farmerList: [],
  growthList: [],
  growthTotal: 0,
  noticeList: [],
  noticeTotal: 0,
  deviceTrendKey: {},
  isAdmin: false,
  pageTransition: {
    name: "router-view",
    mode: "in-out",
  },
};

export default state;
