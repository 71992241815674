<template>
  <div>
    <Navbar v-if="navVisible" :pageName="pageName" />
    <!-- <router-view :key="$route.fullPath" /> -->
    <transition :name="$store.state.pageTransition.name" :mode="$store.state.pageTransition.mode" v-on:after-enter="afterEnter" v-on:after-leave="afterLeave">
      <router-view class="transition" :key="$route.fullPath" />
    </transition>

    <Menu v-if="menuVisible" />
  </div>
</template>
<script>
import Store from "./store/index";
import { mapState } from "vuex";
import Navbar from "@/components/Navbar";
import Menu from "@/components/Menu";
export default {
  components: { Navbar, Menu },
  computed: {
    ...mapState(["navVisible", "menuVisible", "pageName", "pageTransition"]),
  },
  methods: {
    afterEnter: () => {
      window.scrollTo(0, 0);
    },
    afterLeave: () => {
      Store.commit("setPageTransition", "default");
    },
  },
};
</script>
<style lang="scss">
$duration: 0.3s;
.transition {
  overflow: hidden;
}
.router-view,
.router-view-back {
  &-enter-active,
  &-leave-active {
    position: fixed;
    width: 100%;
    background: white;
    min-height: 100vh;
    top: 0;
  }
}
// router view
.router-view-enter-active {
  transition: transform $duration ease-in-out;
  z-index: 2;
  transform: translateX(100%);
}
.router-view-enter-to {
  z-index: 2;
  transform: translateX(0%);
}
.router-view-leave-active {
  z-index: -1;
}
.router-view-leave-to {
  z-index: -1;
}
// router view back

.router-view-back-leave-active {
  transition: transform $duration ease-in-out;
  z-index: 2;
  transform: translateX(0%);
}
.router-view-back-leave-to {
  z-index: 2;
  transform: translateX(100%);
}

@media screen and (min-width: 769px) {
  .router-view,
  .router-view-back {
    &-enter-active,
    &-leave-active {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .router-view-enter-active {
    transition: unset;
    transform: translateX(-50%);
  }
  .router-view-enter-to {
    transform: translateX(-50%);
  }
  .router-view-back-leave-active {
    transition: unset;
    transform: translateX(-50%);
  }
  .router-view-back-leave-to {
    transform: translateX(-50%);
  }
}
</style>
