const mutations = {
  LOGIN(state, data) {
    state.isLogin = true;
    localStorage.setItem("accesstoken", data.accesstoken);
    localStorage.setItem("userType", data.userType);
  },
  LOGOUT(state) {
    state.isLogin = false;
    state.isAdmin = false;
    state.mobileNo = "";
    state.username = "";
    state.userType = null;
    state.appAlarm = null;
    state.id = null;
    state.userId = null;

    localStorage.removeItem("accesstoken");
    localStorage.removeItem("userType");
  },
  SET_NEW_NOTICE(data) {
    localStorage.setItem("noticeCount", data);
  },
  SET_TREND_KEY(state, data) {
    if (data == null || data == undefined) {
      state.deviceTrendKey = {};
    } else {
      state.deviceTrendKey = data;
    }
  },
  SET_NOTICELIST(state, data) {
    if (data == null) {
      state.noticeList = [];
      state.noticeTotal = 0;
    } else {
      if (state.noticeList.length < data.total) {
        state.noticeList = state.noticeList.concat(data.data);
      }
      state.noticeTotal = data.total;
    }
  },
  SET_NAVBAR(state, data) {
    state.navbarState = data;
  },
  SET_NAVBAR_VISIBLE(state, data) {
    state.navVisible = data;
  },
  SET_MENU_VISIBLE(state, data) {
    state.menuVisible = data;
  },
  SET_PAGE_NAME(state, data) {
    state.pageName = data;
  },
  SET_FOOTER(state, data) {
    state.footerVisible = data;
  },
  SET_USER_FARMLIST(state, data) {
    if (data == null) {
      state.farmList = "";
    } else {
      state.farmList = data;
    }
  },
  SET_FARMERLIST(state, data) {
    state.farmerList = data;
  },
  SET_USER_GROWTHLIST(state, data) {
    if (data == null) {
      state.growthList = [];
      state.growthTotal = 0;
    } else {
      if (data.list.page == 1) {
        state.growthList = data.list.data;
      } else {
        if (data.remainder == 0) {
          state.growthList = state.growthList.concat(data.list.data);
        } else {
          let end = data.list.page * 10 - 1;
          let tempList = state.growthList.slice(0, end);
          state.growthList = tempList.concat(data.list.data);
          tempList = [];
        }
      }
      state.growthTotal = data.list.total;
    }
  },
  SET_USER_MAINFARM(state, data) {
    if (data == null) {
      state.mainFarm = "";
    } else {
      state.mainFarm = data;
    }
  },
  SET_LOADING(state, data) {
    state.loading = data;
  },
  SET_WEATHER_ERR(state, data) {
    state.weatherErr = data;
  },
  SET_WEATHER(state, data) {
    if (data == null) {
      state.weather = "";
    } else {
      state.weather = data;
    }
  },
  SET_DEVICEINFO(state, data) {
    if (data == null) {
      state.devices = "";
    } else {
      state.devices = data;
    }
  },
  SET_USER_INFO(state, data) {
    if (data.appAlarm != null) {
      state.appAlarm = data.appAlarm;
    }
    if (data.mobileNo) {
      state.mobileNo = data.mobileNo;
    }
    if (data.userId) {
      state.userId = data.userId;
    }
    if (data.username) {
      state.username = data.username;
    }
    if (data.userType) {
      state.userType = data.userType;
    }
  },
  setPageTransition(state, data) {
    if ("default" === data) {
      state.pageTransition.name = "router-view";
      state.pageTransition.mode = "in-out";
    }
    if ("back" === data) {
      state.pageTransition.name = "router-view-back";
      state.pageTransition.mode = "";
    }
  },
};
export default mutations;
